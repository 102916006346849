// import { mediaQuery } from '@/utils/mediaQuery';
import { mediaQuery } from '@/utils/mediaQuery';
import styled from '@emotion/styled';
import { Carousel } from '@mantine/carousel';
import {
  Button,
  ButtonProps,
  Container,
  Flex,
  Title,
  createPolymorphicComponent,
  keyframes,
} from '@mantine/core';
const infinte = keyframes`
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -2843.67px;
  }
`;
const _StyledButton = styled(Button)<ButtonProps>`
  border-radius: 150px;
  text-transform: uppercase;
  background: border-box;
  background-image: ${(props) => (props.bg ? props.bg : props.theme.colors.redGradient)};
`;
export const StyledButton = createPolymorphicComponent<'button', ButtonProps>(_StyledButton);
export const StyledTitle = styled(Title)`
  font-weight: 800;
  font-style: italic;
  background: ${(props) => (props.bg ? props.bg : props.theme.colors.redGradient)};
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 2rem;
`;
export const StyledCarousel = styled(Carousel)`
  /* height: calc(100vh - 100px); */
  height: fit-content;
`;
export const StyledSlide = styled(Carousel.Slide)`
  /* height: calc(100vh - 100px); */
  height: fit-content;
  /* padding: 1.5rem; */
  ${mediaQuery('md')} {
    padding: 3rem;
  }
  &:nth-child(odd) > div {
    background-color: #fff;
  }
`;
export const Card = styled.div`
  padding: 2rem;
  ${mediaQuery('md')} {
    padding: 3rem;
  }
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  & p {
    margin: 1rem 0;
  }
`;
export const Hero = styled.section`
  width: 100%;
  overflow: hidden;
  animation: ${infinte} 70s linear infinite;
  height: 300px;
  position: relative;
  background-repeat: repeat-x;
  background-position-y: center;
  background-size: auto 100%;
  background-color: #f2f2f2;
  & #mobile_mockup {
    position: absolute;
    bottom: -30px;
    left: -80px;
    height: 275px;
    ${mediaQuery('md')} {
      bottom: -110px;
      height: 700px;
      left: 0;
    }
    object-fit: contain;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(23, 23, 22, 0) -30%, #171316 100%);
    ${mediaQuery('md')} {
      background: linear-gradient(270deg, rgba(23, 23, 22, 0) 18.64%, #171716 69.65%);
    }
  }

  ${mediaQuery('md')} {
    height: calc(100vh - 100px);
  }
`;
export const StyledContainer = styled(Container)`
  width: 100%;
`;
export const TeaserWrapper = styled.div`
  width: 100%;
  aspect-ratio: 128 / 72;
  position: relative;
  box-shadow: 0px 0px 10.1px -26px rgba(0, 0, 0, 1), 0px 0px 20.3px -26px rgba(0, 0, 0, 0.481),
    0px 0px 32.7px -26px rgba(0, 0, 0, 0.349), 0px 0px 53.6px -26px rgba(0, 0, 0, 0.262),
    0px 0px 122px -26px rgba(0, 0, 0, 0.172);
  margin-top: 100px;
  overflow: hidden;
  background-image: url(/assets/capa-teaser.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px 15px 0 0;
  background-position: right;

  button {
    width: 100%;
  }

  ${mediaQuery('md')} {
    background-position-x: unset;
    border-radius: 15px;
    background-image: url(/assets/capa-teaser.jpeg);
  }
`;
export const TeaserContent = styled(Flex)`
  padding: 10px;

  .mantine-Text-root {
    visibility: hidden;
  }
  ${mediaQuery('md')} {
    background-color: transparent;
    .mantine-Text-root {
      visibility: visible;
    }
  }
  & > div {
    ${mediaQuery('md')} {
      text-align: right;
      width: 100%;
    }
    & * {
      /* text-align: center; */
      text-align: right;
    }
  }
`;
export const VideoLoop = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 0px 10.1px -26px rgba(0, 0, 0, 1), 0px 0px 20.3px -26px rgba(0, 0, 0, 0.481),
    0px 0px 32.7px -26px rgba(0, 0, 0, 0.349), 0px 0px 53.6px -26px rgba(0, 0, 0, 0.262),
    0px 0px 122px -26px rgba(0, 0, 0, 0.172);
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #00000045;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
  }
  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
`;

export const BlogCarousel = styled(Carousel)`
  width: 100vw;
  padding: 0px 1rem;
`;

export const BlogCarouselSlide = styled(Carousel.Slide)`
  justify-content: center;
  align-items: center;

  & > div {
    max-width: 500px;
    margin: auto;
  }
`;

export const BlogPostsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
`;

export const ChannelVideoCarousel = styled(Carousel)``;

export const ChannelVideoCarouselSlide = styled(Carousel.Slide)``;

export const SuccessMsgWrapper = styled.div`
  margin: 0.8rem auto;
  width: 400;
  font-size: 0.8rem;
  display: block;
  justify-content: center;
  align-items: center;
`;
