import * as S from '../Home.styles';
import { Container, Grid, useMantineTheme, Box, Flex, Modal, Text } from '@mantine/core';
import { motion } from 'framer-motion';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import Image from 'next/image';
import { containerMotion, itemMotion } from '../utils/motion';
import { FormattedMessage } from 'react-intl';
import { useHomeContext } from '../Home';

const AboutSection = () => {
  const { locale, scrollRef } = useHomeContext();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [aboutOpened, { close: aboutClose, open: aboutOpen }] = useDisclosure(false);

  return (
    <section
      aria-label={locale === 'en-US' ? 'About us' : 'Quem somos'}
      id={locale === 'en-US' ? 'about-us' : 'quem-somos'}
    >
      <Container
        sx={() => ({
          paddingTop: '100px',
          paddingBottom: '100px',
          '@media (max-width: 600px)': { paddingTop: '25px', paddingBottom: '25px' },
        })}
        size={'xl'}
      >
        <motion.div
          initial={{ opacity: 0, y: 300 }}
          transition={{ duration: 1, mass: 1, type: 'tween' }}
          whileInView={{ opacity: [0, 0.2, 0.3, 0.5, 1], y: 0 }}
          viewport={{ root: scrollRef }}
        >
          <S.StyledCarousel slideGap="1rem" mx="auto" loop withIndicators withControls={!isMobile}>
            {/* Sobre Nós */}
            <S.StyledSlide>
              <S.Card>
                <Grid>
                  <Grid.Col
                    order={isMobile ? 1 : 0}
                    md={4}
                    sx={{ height: isMobile ? '250px' : '350px' }}
                  >
                    <Box style={{ width: '100%', height: '100%', position: 'relative' }}>
                      <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        style={{ width: '100%', height: '100%' }}
                        transition={{ duration: 1, mass: 1, type: 'tween' }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ root: scrollRef }}
                      >
                        <Image
                          fill
                          style={{ objectFit: 'contain' }}
                          src="/assets/quem-somos.png"
                          alt={
                            locale === 'en-US'
                              ? 'A hand holding a glass of wine, in the background an open laptop displaying another person tasting wine'
                              : 'Uma mão segurando uma taça de vinho, no fundo um notebook aberto exibindo outra pessoa degustando vinho'
                          }
                        />
                      </motion.div>
                    </Box>
                  </Grid.Col>

                  <Grid.Col order={isMobile ? 0 : 1} md={8}>
                    <Flex h="100%" align={'center'}>
                      <Box>
                        <motion.div
                          variants={containerMotion}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ root: scrollRef }}
                        >
                          <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                            <S.StyledTitle order={2}>
                              <FormattedMessage id="slide.about.title" />
                            </S.StyledTitle>
                          </motion.div>
                          <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                            <Text component="p" color="dark" lineClamp={5}>
                              <FormattedMessage
                                values={{
                                  br: () => <br />,
                                }}
                                id="slide.about.description"
                              />
                            </Text>
                          </motion.div>
                          <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                            <S.StyledButton onClick={aboutOpen}>
                              <FormattedMessage id="general.learnMore" />
                            </S.StyledButton>
                          </motion.div>

                          <Modal
                            styles={{
                              close: { color: '#544A4C' },
                              title: { fontWeight: 'bold' },
                              header: {
                                color: '#544A4C',
                                backgroundColor: '#fff',
                                borderBottom: '1px solid #eee',
                              },
                              body: { backgroundColor: '#fff' },
                            }}
                            transitionProps={{ transition: 'rotate-left' }}
                            size={'xl'}
                            centered
                            title={<FormattedMessage id="slide.about.title" />}
                            opened={aboutOpened}
                            onClose={aboutClose}
                          >
                            <Text component="p" c={'darkText'} py={10}>
                              <FormattedMessage
                                values={{
                                  br: () => <br />,
                                }}
                                id="slide.about.description"
                              />
                            </Text>
                          </Modal>
                        </motion.div>
                      </Box>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </S.Card>
            </S.StyledSlide>
            {/* Visão */}
            <S.StyledSlide>
              <S.Card style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <Box ta={'center'} mb="50px">
                  <motion.div
                    variants={containerMotion}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ root: scrollRef }}
                  >
                    <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                      <S.StyledTitle bg={'silverGradient'} style={{ color: 'white' }} order={2}>
                        <FormattedMessage id="slide.vision.title" />
                      </S.StyledTitle>
                    </motion.div>
                    <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                      <Text component="p" color={'text'} lineClamp={5}>
                        <FormattedMessage id="slide.vision.description" />
                      </Text>
                    </motion.div>
                  </motion.div>
                </Box>
                <Box style={{ width: '100%', height: '180px', position: 'relative' }}>
                  <motion.div
                    initial={{ opacity: 0, x: 80 }}
                    style={{ width: '100%', height: '100%' }}
                    transition={{ duration: 1, mass: 1, type: 'tween' }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ root: scrollRef }}
                  >
                    <Image
                      fill
                      style={{ objectFit: 'contain' }}
                      src="/assets/visao-sl.png"
                      alt={
                        locale === 'en-US'
                          ? 'A hand with a glass of wine in front of a laptop'
                          : 'Uma mão com uma taça de vinho em frente à um notebook'
                      }
                    />
                  </motion.div>
                </Box>
              </S.Card>
            </S.StyledSlide>
            {/* Valores */}
            <S.StyledSlide>
              <S.Card>
                <Grid>
                  <Grid.Col md={8}>
                    <Flex h="100%" align={'center'}>
                      <Box>
                        <motion.div
                          variants={containerMotion}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ root: scrollRef }}
                        >
                          <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                            <S.StyledTitle bg={'goldenGradient'} order={2}>
                              <FormattedMessage id="slide.values.title" />
                            </S.StyledTitle>
                          </motion.div>
                          <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                            <Text component="p" color="dark" lineClamp={15}>
                              <FormattedMessage id="slide.values.description" />
                            </Text>
                          </motion.div>
                        </motion.div>
                      </Box>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col md={4} style={{ height: isMobile ? '200px' : '350px' }}>
                    <Box style={{ width: '100%', height: '100%', position: 'relative' }}>
                      <motion.div
                        initial={{ opacity: 0, x: 80 }}
                        style={{ width: '100%', height: '100%' }}
                        transition={{ duration: 1, mass: 1, type: 'tween' }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ root: scrollRef }}
                      >
                        <Image
                          fill
                          style={{ objectFit: 'contain' }}
                          src="/assets/valores.png"
                          alt={
                            locale === 'en-US'
                              ? 'Four hands overlapping each other, representing union and teamwork'
                              : 'Quatro mãos sobrepondo umas às outras, representando união e trabalho em equipe'
                          }
                        />
                      </motion.div>
                    </Box>
                  </Grid.Col>
                </Grid>
              </S.Card>
            </S.StyledSlide>
            {/* Missão */}
            <S.StyledSlide>
              <S.Card>
                <Grid>
                  <Grid.Col
                    order={isMobile ? 1 : 0}
                    md={4}
                    style={{ height: isMobile ? '250px' : '350px' }}
                  >
                    <Box style={{ width: '100%', height: '100%', position: 'relative' }}>
                      <motion.div
                        initial={{ opacity: 0, x: 80 }}
                        style={{ width: '100%', height: '100%' }}
                        transition={{ duration: 1, mass: 1, type: 'tween' }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ root: scrollRef }}
                      >
                        <Image
                          fill
                          style={{ objectFit: 'contain' }}
                          src="/assets/quem-somos.png"
                          alt={
                            locale === 'en-US'
                              ? 'A hand holding a glass of wine, in the background an open laptop displaying another person tasting wine'
                              : 'Uma mão segurando uma taça de vinho, no fundo um notebook aberto exibindo outra pessoa degustando vinho'
                          }
                        />
                      </motion.div>
                    </Box>
                  </Grid.Col>
                  <Grid.Col order={isMobile ? 0 : 1} md={8}>
                    <Flex h="100%" align={'center'}>
                      <Box>
                        <motion.div
                          variants={containerMotion}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ root: scrollRef }}
                        >
                          <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                            <S.StyledTitle order={2}>
                              <FormattedMessage id="slide.mission.title" />
                            </S.StyledTitle>
                          </motion.div>
                          <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                            <Text component="p" color={'text'} lineClamp={15}>
                              <FormattedMessage id="slide.mission.description" />
                            </Text>
                          </motion.div>
                        </motion.div>
                      </Box>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </S.Card>
            </S.StyledSlide>
          </S.StyledCarousel>
        </motion.div>
      </Container>
    </section>
  );
};

export default AboutSection;
