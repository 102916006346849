import { Box, Container } from '@mantine/core';
import { containerMotion, itemList, itemMotion } from '../utils/motion';
import { FormattedMessage } from 'react-intl';
import ChannelVideoCard from '@/components/ChannelVideoCard/ChannelVideoCard';
import Link from 'next/link';
import { ChannelVideo } from '@/typings/channelVideo';
import { motion } from 'framer-motion';
import * as S from '../Home.styles';
import { useHomeContext } from '../Home';

type Props = {
  channelVideos: ChannelVideo[];
};

const ChannelVideosSection = ({ channelVideos }: Props) => {
  const { locale, scrollRef } = useHomeContext();

  return (
    <section
      id={locale === 'en-US' ? 'channel' : 'canal'}
      aria-label={locale === 'en-US' ? 'Our Channel' : 'Nosso Canal'}
    >
      <Box
        px="md"
        bg={'linear-gradient(180deg, #F9FAFA 0%, #F5F6F7 100%);'}
        sx={() => ({
          paddingTop: '180px',
          paddingBottom: '180px',
          '@media (max-width: 600px)': { paddingTop: '100px', paddingBottom: '100px' },
        })}
        style={{ overflow: 'hidden' }}
      >
        <motion.div
          variants={containerMotion}
          initial="hidden"
          whileInView="show"
          viewport={{ root: scrollRef }}
        >
          <S.StyledTitle order={2} align="center" mb={70}>
            <FormattedMessage id="channel.title" />
          </S.StyledTitle>
        </motion.div>
        <motion.div
          variants={containerMotion}
          initial="hidden"
          whileInView="show"
          viewport={{ root: scrollRef }}
        >
          <Container size={'xl'} ta="center">
            <motion.div variants={itemList} viewport={{ root: scrollRef }}>
              <S.ChannelVideoCarousel
                slideSize="33.333333%"
                slideGap={15}
                mx="auto"
                w="100%"
                align="start"
                controlsOffset={-20}
                breakpoints={[
                  { maxWidth: 'md', slideSize: '50%' },
                  { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
                ]}
                controlSize={32}
                styles={{
                  viewport: {
                    padding: '20px 0',
                  },
                }}
              >
                {channelVideos.map((video) => (
                  <S.ChannelVideoCarouselSlide key={video.id}>
                    <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
                      <ChannelVideoCard
                        {...video}
                        textColor="dark"
                        gradientColor={video.category?.color}
                        disableVertical={true}
                      />
                    </motion.div>
                  </S.ChannelVideoCarouselSlide>
                ))}
              </S.ChannelVideoCarousel>
            </motion.div>
            <motion.div>
              <Link href={'/canal'}>
                <S.StyledButton mt={70}>
                  <FormattedMessage id="channel.access" />
                </S.StyledButton>
              </Link>
            </motion.div>
          </Container>
        </motion.div>
      </Box>
    </section>
  );
};

export default ChannelVideosSection;
