import styled from '@emotion/styled';
import { rem } from '@mantine/core';
import Link from 'next/link';

export const Wrapper = styled(Link)`
  background-color: white;
  width: 100%;
  height: 100%;
  text-decoration: none;
  transition: all 0.2s;
  display: inherit;

  &:hover {
    filter: brightness(0.9);
    transform: scale(1.05);
  }

  div.banner-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;

    h2 {
      color: #25282b;
      font-size: ${rem(18)};
      font-weight: 700;
      line-height: 1.3;
    }

    p {
      color: black;
      font-size: ${rem(16)};
      font-weight: 400;
    }
  }
`;
