import VideoModal from '@/components/VideoModal/VideoModal';
import { Button, Text, Container, Box, useMantineTheme } from '@mantine/core';
import { FormattedMessage } from 'react-intl';
import { PlayerPlay } from 'tabler-icons-react';
import { Variants, motion } from 'framer-motion';
import * as S from '../Home.styles';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { containerMotion } from '../utils/motion';
import { useHomeContext } from '../Home';

const TeaserSection = () => {
  const { locale, scrollRef } = useHomeContext();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [teaserOpened, { close: teaserClose, open: teaserOpen }] = useDisclosure(false);

  const videoURL = {
    en: 'https://streamable.com/od0iq0',
    pt: 'https://streamable.com/od0iq0',
  };

  const itemMotionX: Variants = {
    hidden: { opacity: 0, x: 50 },
    show: {
      transition: {
        mass: 1,
        duration: 0.6,
        type: 'tween',
      },
      opacity: [0, 0.2, 0.3, 0.5, 1],
      x: 0,
    },
  };

  return (
    <section aria-label="Teaser" id="teaser">
      <Container
        size={'lg'}
        sx={() => ({
          paddingTop: '100px',
          '@media (max-width: 600px)': { paddingTop: '25px' },
        })}
      >
        <motion.div
          initial={{ opacity: 0, y: 300 }}
          transition={{ duration: 1, mass: 1, type: 'tween' }}
          whileInView={{ opacity: [0, 0.2, 0.3, 0.5, 1], y: 0 }}
          viewport={{ root: scrollRef }}
        >
          <S.TeaserWrapper>
            <VideoModal
              opened={teaserOpened}
              onClose={teaserClose}
              url={locale === 'en-US' ? videoURL.en : videoURL.pt}
            />
            {!isMobile && (
              <S.TeaserContent
                justify={'flex-end'}
                align={'center'}
                w={'100%'}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  height: '100%',
                }}
              >
                <Container size="xl" w={'100%'}>
                  <Box style={{ float: 'right' }}>
                    <motion.div
                      variants={containerMotion}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ root: scrollRef }}
                    >
                      <motion.div variants={itemMotionX} viewport={{ root: scrollRef }}>
                        <Text w={250} ta={'right'} pb={20}>
                          <FormattedMessage id="teaser.title" />
                        </Text>
                      </motion.div>
                      <motion.div variants={itemMotionX} viewport={{ root: scrollRef }}>
                        <Button
                          onClick={teaserOpen}
                          style={{ color: 'black', clear: 'both' }}
                          variant="white"
                          size="lg"
                          leftIcon={<PlayerPlay />}
                        >
                          <FormattedMessage id="teaser.cta" />
                        </Button>
                      </motion.div>
                    </motion.div>
                  </Box>
                </Container>
              </S.TeaserContent>
            )}
          </S.TeaserWrapper>
        </motion.div>
        {isMobile && (
          <Box bg="#00000189" sx={{ borderRadius: `0 0 15px 15px` }}>
            <motion.div variants={itemMotionX} viewport={{ root: scrollRef }}>
              <Button
                onClick={teaserOpen}
                style={{ color: 'black', clear: 'both' }}
                variant="white"
                size="lg"
                mx="md"
                mt="md"
                w="calc(100% - 32px)"
                leftIcon={<PlayerPlay />}
              >
                <FormattedMessage id="teaser.cta" />
              </Button>
            </motion.div>
            <motion.div
              variants={containerMotion}
              initial="hidden"
              whileInView="show"
              viewport={{ root: scrollRef }}
              // style={{ maxWidth: '100%', overflow: 'hidden' }}
            >
              <motion.div variants={itemMotionX} viewport={{ root: scrollRef }}>
                <Text mx="auto" p="md" ta={'center'} pb={20}>
                  <FormattedMessage id="teaser.title" />
                </Text>
              </motion.div>
            </motion.div>
          </Box>
        )}
      </Container>
    </section>
  );
};

export default TeaserSection;
