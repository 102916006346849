import { Box, Container } from '@mantine/core';
import * as S from '../Home.styles';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useHomeContext } from '../Home';

export default function HeroSection() {
  const { locale } = useHomeContext();

  return (
    <S.Hero
      aria-label={locale === 'en-US' ? 'Home' : 'Início'}
      id="home"
      style={{ backgroundImage: `url(/assets/hero-bg-3.png)` }}
    >
      <motion.div
        animate={{ opacity: [0, 0.2, 0.3, 0.5, 1], y: [150, 0] }}
        transition={{ duration: 1, mass: 2, type: 'tween' }}
      >
        <Container size={'xl'} pos={'relative'}>
          <Box>
            <Image
              width={400}
              height={750}
              id="mobile_mockup"
              src="/assets/mobile-mockup.png"
              alt={
                locale === 'en-US'
                  ? 'Collage wall containing photos of wineries and wine in bottles and glasses, and in a prominent position a hand holding a cell phone with the Enoteca Mundi app open'
                  : 'Mural de colagens contendo fotos de vinícolas e vinhos em garrafas e taças, e em posição de destaque uma mão segurando um celular com o aplicativo da Enoteca Mundi aberto'
              }
            />
          </Box>
        </Container>
      </motion.div>
    </S.Hero>
  );
}
