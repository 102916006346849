import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';

interface WrapperProps {
  gradientColor: string;
  imageUrl: string;
  showGradient: boolean;
  vertical?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  aspect-ratio: ${(props) => (props.vertical ? 9 / 16 : 16 / 9)};
  background: ${(props) =>
    props.showGradient
      ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${props.gradientColor} 100%), url(${props.imageUrl}) center`
      : `url(${props.imageUrl}) center`};
  background-size: cover;
  max-height: ${(props) => (props.vertical ? `400px` : ``)};
  max-width: ${(props) => (props.vertical ? `200px` : ``)};
  transition: 0.1s all ease-in-out;
  text-align: left;
  :hover {
    transform: scale(1.05);
  }
`;

export const _StyledButton = styled(Button)<ButtonProps>`
  color: 'white';
  clear: 'both';
  border: '0';
  border-bottom: '2px solid white';
  background-color: 'transparent';
  border-radius: 0;
  width: 'fit-content';
  padding: 0;
  font-weight: 'bold';
`;
export const StyledButton = createPolymorphicComponent<'button', ButtonProps>(_StyledButton);
