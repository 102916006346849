import { BlogPost } from '@/typings/blog';
import { ChannelVideo } from '@/typings/channelVideo';
import { VideoCategory } from '@/typings/videoCategory';
import { firebaseAdmin } from '@/utils/firebaseAdmin';
import Home from '@/views/Home/Home';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { GetStaticProps, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import Head from 'next/head';

export type HomeProps = {
  blogPosts: BlogPost[];
  channelVideos: ChannelVideo[];
  locale: string;
};

const Root: NextPage<HomeProps> = (props) => {
  return (
    <>
      <NextSeo title={undefined} />
      <Head>
        <link rel="preload" href="/assets/hero-bg-3.png" as="image" />
        <link rel="preload" href="/assets/mobile-mockup.png" as="image" />
      </Head>
      <Home {...props} />
    </>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const db = firebaseAdmin.firestore();
  const blogPostsCollection = db.collection('blogPosts');
  const { docs } = await blogPostsCollection
    .where('deletedAt', '==', null)
    .where('isPublished', '==', true)
    .get();

  const now = dayjs();

  const blogPosts: BlogPost[] = (docs || [])
    .map((doc) => ({
      ...(doc.data() as BlogPost),
      id: doc.id,
    }))
    .filter((post) =>
      post.publishedAt ? now.isAfter((post.publishedAt as Timestamp).toDate()) : false,
    )
    .sort((a, b) => {
      const aDate = (a.publishedAt as Timestamp).toDate();
      const bDate = (b.publishedAt as Timestamp).toDate();
      return dayjs(aDate).isBefore(dayjs(bDate)) ? 1 : -1;
    });

  const channelVideosCollection = db.collection('channelVideos');
  const { docs: channelVideosDocs } = await channelVideosCollection
    .where('deletedAt', '==', null)
    .orderBy('order', 'asc')
    .get();

  const videoCategoryCollection = db.collection('videoCategories');
  const { docs: categoryDocs } = await videoCategoryCollection
    .where('deletedAt', '==', null)
    .orderBy('order', 'asc')
    .get();

  const categories: VideoCategory[] = (categoryDocs || []).map((doc) => ({
    ...(doc.data() as VideoCategory),
    id: doc.id,
  }));

  const channelVideos: ChannelVideo[] = (channelVideosDocs || []).map((doc) => {
    const video = doc.data() as ChannelVideo;
    return {
      ...video,
      id: doc.id,
      category: categories.find((category) => category.id === video.categoryId),
    };
  });

  return {
    props: {
      locale: context.locale,
      blogPosts: JSON.parse(JSON.stringify(blogPosts.slice(0, 3))),
      channelVideos: JSON.parse(JSON.stringify(channelVideos)),
    },
    // 30 days
    revalidate: 2592000,
  };
};

export default Root;
