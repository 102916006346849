import React from 'react';
import { ChannelVideo } from '@/typings/channelVideo';
import { Flex, Text, ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import VideoModal from '../VideoModal/VideoModal';
import * as S from './ChannelVideoCard.styles';
import Link from 'next/link';

type ChannelVideoCardProps = ChannelVideo & {
  gradientColor?: string;
  textColor?: string;
  shouldOpenModalOnClick?: boolean;
  disableVertical?: boolean;
};

const ChannelVideoCard: React.FC<ChannelVideoCardProps> = ({
  id,
  image,
  thumbnail,
  title,
  url,
  slug,
  verticalVideo,
  disableVertical,
  streamableUrl,
  description,
  gradientColor = '#962133',
  textColor = 'white',
  shouldOpenModalOnClick,
  isOverlayDisabled,
}) => {
  const [modalOpened, { close: modalClose, open: modalOpen }] = useDisclosure(false);
  const displayVertical = !disableVertical && verticalVideo && !!thumbnail;
  /**
   * Giving preference to Youtube URL
   */
  return (
    <>
      <VideoModal
        url={(url || streamableUrl) as string}
        title={title}
        description={description}
        onClose={modalClose}
        opened={modalOpened}
        vertical={displayVertical}
      />
      <S.Wrapper
        key={id}
        showGradient={!isOverlayDisabled}
        gradientColor={gradientColor}
        imageUrl={displayVertical || (!verticalVideo && thumbnail) ? thumbnail : image}
        vertical={displayVertical}
      >
        <Flex
          h="100%"
          justify="center"
          align="center"
          pos="relative"
          inset={0}
          style={{ zIndex: 1 }}
        >
          {shouldOpenModalOnClick ? (
            <ActionIcon
              title="Assistir"
              onClick={modalOpen}
              variant="white"
              style={{
                color: 'white',
                backgroundColor: 'transparent',
                width: '100%',
                height: '100%',
              }}
            >
              <IconPlayerPlayFilled
                style={{
                  margin: 'auto',
                  border: '2px solid rgb(255,255,255, 0.5)',
                  borderRadius: '50%',
                  padding: '8px',
                }}
                size={80}
              />
            </ActionIcon>
          ) : (
            slug && (
              <Link
                href={`/canal/${slug}`}
                style={{
                  color: 'white',
                  backgroundColor: 'transparent',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconPlayerPlayFilled
                  style={{
                    margin: 'auto',
                    border: '2px solid rgb(255,255,255, 0.5)',
                    borderRadius: '50%',
                    padding: '8px',
                  }}
                  fill="white"
                  size={80}
                />
              </Link>
            )
          )}
        </Flex>
      </S.Wrapper>
      {title && (
        <Text
          w="90%"
          mih={50}
          mt="md"
          color={textColor}
          weight="bold"
          size="md"
          lineClamp={3}
          style={{ zIndex: 1 }}
        >
          {title}
        </Text>
      )}
    </>
  );
};

export default ChannelVideoCard;
