import { Variants } from 'framer-motion';

export const containerMotion: Variants = {
  hidden: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
};

export const itemMotion: Variants = {
  hidden: { opacity: 0, y: 50 },
  show: {
    transition: {
      mass: 1,
      duration: 0.6,
      type: 'tween',
    },
    opacity: [0, 0.2, 0.3, 0.5, 1],
    y: 0,
  },
};

export const itemList: Variants = {
  show: {
    opacity: [0, 0.2, 0.3, 0.5, 1],
    y: 0,
    transition: {
      mass: 1,
      duration: 0.6,
      type: 'tween',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
  },
};

export const itemVariants: Variants = {
  show: {
    opacity: 1,
    transition: { type: 'tween' },
    display: 'inherit',
  },
  hidden: { opacity: 0 },
};
