import { Container } from '@mantine/core';
import dynamic from 'next/dynamic';
import { motion } from 'framer-motion';
import * as S from '../Home.styles';
import { useHomeContext } from '../Home';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

const VideoLoopingSection = () => {
  const { locale, scrollRef } = useHomeContext();

  const videoURL = {
    en: 'https://www.youtube.com/embed/neH8N57ATkk',
    pt: 'https://www.youtube.com/embed/tx58rrFUugU',
  };

  return (
    <section aria-label="Video">
      <Container
        size={'lg'}
        sx={() => ({
          paddingTop: '150px',
          paddingBottom: '150px',
          '@media (max-width: 600px)': { paddingTop: '25px', paddingBottom: '25px' },
        })}
      >
        <motion.div
          initial={{ opacity: 0, y: 300 }}
          transition={{ duration: 1, mass: 1, type: 'tween' }}
          whileInView={{ opacity: [0, 0.2, 0.3, 0.5, 1], y: 0 }}
          viewport={{ root: scrollRef }}
        >
          <S.VideoLoop>
            <ReactPlayer
              playing
              loop
              playsinline
              muted
              config={{ youtube: { playerVars: { controls: 0, autoplay: 1, mute: 1 } } }}
              width="100%"
              height="100%"
              url={locale === 'en-US' ? videoURL.en : videoURL.pt}
            />
          </S.VideoLoop>
        </motion.div>
      </Container>
    </section>
  );
};

export default VideoLoopingSection;
