import React from 'react';
import * as S from './BlogPostCard.styles';
import { BlogPost } from '@/typings/blog';
import Image from 'next/image';
import { Title } from '@mantine/core';

type BlogPostCardProps = BlogPost;

const BlogPostCard: React.FC<BlogPostCardProps> = ({ title, shortDescription, banner, slug }) => {
  return (
    <S.Wrapper href={`/blog/post/${slug}`}>
      <div className="banner-container">
        <Image src={banner} alt="" fill />
      </div>
      <div className="content">
        <Title order={2}>{title}</Title>
        <p>{shortDescription}</p>
      </div>
    </S.Wrapper>
  );
};

export default BlogPostCard;
