import React, { useRef } from 'react';
import { HomeProps } from '@/pages';
import HeroSection from './components/HeroSection';
import TeaserSection from './components/TeaserSection';
import AboutSection from './components/AboutSection';
import DownloadSection from './components/DownloadSection';
import BlogsSection from './components/BlogsSection';
import ChannelVideosSection from './components/ChannelVideosSection';
import VideoLoopingSection from './components/VideoLoopingSection';
import NewsletterSection from './components/NewsletterSection';

const HomeContext = React.createContext<{
  scrollRef: React.RefObject<Element> | undefined;
  locale: string;
}>({ scrollRef: undefined, locale: '' });

export const useHomeContext = () => React.useContext(HomeContext);

const Home: React.FC<HomeProps> = ({ blogPosts, channelVideos, locale }) => {
  const scrollRef = useRef<HTMLElement>(null);

  return (
    <>
      <h1 className="sr-only">Enoteca Mundi</h1>
      <HomeContext.Provider
        value={{ scrollRef: scrollRef as React.RefObject<Element> | undefined, locale }}
      >
        <HeroSection />
        <TeaserSection />
        <AboutSection />
        <DownloadSection />
        {blogPosts.length > 0 && <BlogsSection blogPosts={blogPosts} />}
        {channelVideos.length > 0 && <ChannelVideosSection channelVideos={channelVideos} />}
        <VideoLoopingSection />

        <NewsletterSection />
      </HomeContext.Provider>
    </>
  );
};

export default Home;
