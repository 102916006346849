import { Box, Container, useMantineTheme } from '@mantine/core';
import { containerMotion, itemList, itemVariants } from '../utils/motion';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { useMediaQuery } from '@mantine/hooks';
import * as S from '../Home.styles';
import BlogPostCard from '@/components/BlogPostCard/BlogPostCard';
import { BlogPost } from '@/typings/blog';
import { useHomeContext } from '../Home';

type Props = {
  blogPosts: BlogPost[];
};

const BlogsSection = ({ blogPosts }: Props) => {
  const { locale, scrollRef } = useHomeContext();

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <section id={'blog'} aria-label={locale === 'en-US' ? 'Last from Blog' : 'Últimas do Blog'}>
      <Box
        pos={'relative'}
        px="md"
        sx={() => ({
          paddingTop: '180px',
          paddingBottom: '180px',
          '@media (max-width: 600px)': { paddingTop: '100px', paddingBottom: '100px' },
        })}
        style={{ overflow: 'hidden' }}
      >
        <motion.div
          variants={containerMotion}
          initial="hidden"
          whileInView="show"
          viewport={{ root: scrollRef }}
        >
          <S.StyledTitle order={2} align="center" mb={70}>
            <FormattedMessage id="blog.title" />
          </S.StyledTitle>
        </motion.div>
        <motion.div
          variants={containerMotion}
          initial="hidden"
          whileInView="show"
          viewport={{ root: scrollRef }}
        >
          <Container
            size={'xl'}
            style={{
              zIndex: 1,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <motion.div variants={itemList} viewport={{ root: scrollRef }}>
              {isMobile ? (
                <S.BlogCarousel
                  slideSize={`100%`}
                  slideGap={20}
                  styles={{
                    viewport: {
                      padding: '20px 5px',
                    },
                  }}
                >
                  {blogPosts.map((post) => (
                    <S.BlogCarouselSlide key={post.id}>
                      <BlogPostCard {...post} />
                    </S.BlogCarouselSlide>
                  ))}
                </S.BlogCarousel>
              ) : (
                <S.BlogPostsWrapper>
                  {blogPosts.map((post) => (
                    <motion.div key={post.id} variants={itemVariants}>
                      <BlogPostCard key={post.id} {...post} />
                    </motion.div>
                  ))}
                </S.BlogPostsWrapper>
              )}
            </motion.div>
            <motion.div>
              <Link href={'/blog'}>
                <S.StyledButton mt={70}>
                  <FormattedMessage id="blog.access" />
                </S.StyledButton>
              </Link>
            </motion.div>
          </Container>
        </motion.div>
      </Box>
    </section>
  );
};

export default BlogsSection;
