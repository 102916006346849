import { Box, Button, Center, Flex, Text, TextInput, useMantineTheme } from '@mantine/core';
import { containerMotion, itemMotion } from '../utils/motion';
import { FormattedMessage } from 'react-intl';
import * as S from '../Home.styles';
import { useMediaQuery } from '@mantine/hooks';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { IconCircleCheck } from '@tabler/icons-react';
import { motion } from 'framer-motion';
import { useApi } from '@/hooks/useApi';
import { NewsletterSubscriber } from '@/typings/newsletterSubscriber';
import { Args } from '@/hooks/useFirestoreApi';
import { useState } from 'react';
import { useHomeContext } from '../Home';
import ClientComponent from '@/components/ClientComponent/ClientComponent';

const NewsletterSection = () => {
  const { scrollRef } = useHomeContext();

  return (
    <section aria-label="Newsletter">
      <Flex
        align={'center'}
        justify={'center'}
        sx={() => ({
          paddingTop: '150px',
          paddingBottom: '150px',
          '@media (max-width: 600px)': { paddingTop: '25px', paddingBottom: '25px' },
        })}
      >
        <Box style={{ textAlign: 'center' }}>
          <motion.div
            variants={containerMotion}
            initial="hidden"
            whileInView="show"
            viewport={{ root: scrollRef }}
          >
            <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
              <S.StyledTitle order={2}>
                <FormattedMessage id="newsletter.title" />
              </S.StyledTitle>
            </motion.div>
            <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
              <Text component="p" maw="80%" m="auto">
                <FormattedMessage id="newsletter.description" />
              </Text>
            </motion.div>

            <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
              <ClientComponent>
                <NewsletterForm />
              </ClientComponent>
            </motion.div>
          </motion.div>
        </Box>
      </Flex>
    </section>
  );
};

const NewsletterForm = () => {
  const { locale } = useHomeContext();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [_, setSelectedRecord] = useState<NewsletterSubscriber | null>(null);
  const { create } = useApi<NewsletterSubscriber, Args<'newsletterSubscribers'>>(
    'newsletterSubscribers',
  );

  const isEn = locale === 'en-US';

  const form = useForm<NewsletterSubscriber>({
    initialValues: {
      id: '',
      email: '',
    },
    validate: {
      email: (value) => {
        const trimmedValue = value.trim();

        return (
          isNotEmpty(isEn ? 'Email is required' : 'E-mail é obrigatório')(trimmedValue) ||
          isEmail(isEn ? 'Invalid email' : 'E-mail inválido')(trimmedValue)
        );
      },
    },
  });

  const handleSubmit = (data: NewsletterSubscriber) => {
    data.email = data.email.trim();
    setSuccess(false);
    setLoading(true);
    create(data, {
      errorMessage: 'Falha ao se inscrever',
      showNotification: false,
      onSuccess: (id) => {
        setSelectedRecord({ ...data, id });
        setLoading(false);
        setSuccess(true);
        form.reset();
      },
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Center>
        <Flex
          maw={420}
          w={isMobile ? '98%' : 420}
          direction="column"
          mx="auto"
          align="center"
          justify="center"
          my={50}
        >
          <TextInput
            placeholder={isEn ? 'Type your email' : 'Digite seu e-mail'}
            w={isMobile ? '90%' : '100%'}
            styles={() => ({
              input: { borderRadius: '50px', paddingRight: 130, fontSize: 14 },
            })}
            size="lg"
            rightSection={
              <Button
                style={{
                  position: 'absolute',
                  right: 0,
                  borderRadius: '50px',
                  margin: '0 5px',
                }}
                loading={loading}
                type="submit"
              >
                <FormattedMessage id="newsletter.button" />
              </Button>
            }
            {...form.getInputProps('email')}
          />
          {success && (
            <S.SuccessMsgWrapper>
              <Center mb={4}>
                <IconCircleCheck
                  color={theme.colors.green[6]}
                  style={{ marginRight: 4, width: 16, height: 16 }}
                />
                <FormattedMessage id="newsletter.success.row1" />
              </Center>
              <FormattedMessage id="newsletter.success.row2" />
            </S.SuccessMsgWrapper>
          )}
        </Flex>
      </Center>
    </form>
  );
};

export default NewsletterSection;
