import React from 'react';
import { Modal, Title, useMantineTheme } from '@mantine/core';
import ReactPlayer from 'react-player';
import { useRollbar } from '@rollbar/react';
import { useMediaQuery } from '@mantine/hooks';
import { getOS } from '@/utils/device';

type VideoModalProps = {
  url: string;
  title?: string;
  description?: string;
  opened: boolean;
  onClose: () => void;
  vertical?: boolean;
};

const VideoModal: React.FC<VideoModalProps> = ({
  url,
  opened,
  onClose,
  title,
  description,
  vertical,
}) => {
  const rollbar = useRollbar();
  const isEmbed = url.includes('<iframe');
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      // size={isMobile ? '100vw' : 'auto'}
      size="auto"
      styles={{
        body: {
          ...(isMobile && { padding: 0 }),
        },
        header: {
          ...(isMobile && { marginTop: 60, marginBottom: 10 }),
        },
      }}
      fullScreen={isMobile}
      id="modal-vid"
      centered
      style={{ position: 'absolute', height: '100%' }}
      transitionProps={{ transition: 'fade', duration: 200 }}
    >
      {isEmbed ? (
        <div
          style={{
            width: isMobile || vertical ? '100%' : '1000px',
            maxWidth: isMobile || vertical ? '100%' : '70vw',
            maxHeight: vertical ? '80vh' : '100%',
            aspectRatio: '16 / 9',
            margin: 'auto',
          }}
          dangerouslySetInnerHTML={{ __html: url }}
        />
      ) : (
        <ReactPlayer
          controls={true}
          playing={getOS() === 'iOS' ? false : true}
          width={isMobile || vertical ? '100%' : '1000px'}
          style={{
            maxWidth: isMobile || vertical ? '100%' : '70vw',
            maxHeight: vertical ? '80vh' : '100%',
            aspectRatio: vertical ? '9 / 16' : '16 / 9',
            margin: 'auto',
          }}
          height="auto"
          url={url}
          onError={(e) => {
            rollbar.error('Error playing video', { error: e, url, message: e?.message });
          }}
        />
      )}
      {title && <Title>{title}</Title>}
      {description && (
        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Modal>
  );
};

export default VideoModal;
