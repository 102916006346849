import { Box, Loader } from '@mantine/core';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.enoteca.enotecamundi';
export const appStoreLink = 'https://apps.apple.com/br/app/enoteca-mundi/id6502299097';

export default function DownloadView() {
  const router = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      const userAgent = navigator.userAgent;
      if (/android/i.test(userAgent)) {
        window.location.href = playStoreLink;
      } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        window.location.href = appStoreLink;
      } else {
        router.push('/#onde-baixar');
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [router]);

  return (
    <Box className="h-screen w-full flex flex-col items-center justify-center gap-8">
      <Box style={{ aspectRatio: 2.4 }} className="relative h-auto w-[90%] sm:h-[200px] sm:w-auto">
        <Image src="/assets/logos/logo-dark.png" alt="Coming soon" fill />
      </Box>

      <Loader size={84} />
    </Box>
  );
}
