import { Anchor, Box, Container, MediaQuery, Text, Group } from '@mantine/core';
import { containerMotion, itemMotion } from '../utils/motion';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { motion } from 'framer-motion';
import * as S from '../Home.styles';
import { useHomeContext } from '../Home';
import { appStoreLink, playStoreLink } from '@/views/Download/DownloadView';

const DownloadSection = () => {
  const { locale, scrollRef } = useHomeContext();

  const isEn = locale === 'en-US';

  return (
    <section
      id={locale === 'en-US' ? 'download' : 'onde-baixar'}
      aria-label={locale === 'en-US' ? 'Download' : 'Onde baixar'}
    >
      <Box
        pos={'relative'}
        bg={'linear-gradient(180deg, #F9FAFA 0%, #F5F6F7 100%);'}
        sx={() => ({
          paddingTop: '180px',
          paddingBottom: '180px',
          '@media (max-width: 600px)': { paddingTop: '80px', paddingBottom: '80px' },
        })}
        style={{ overflow: 'hidden' }}
      >
        <motion.div
          variants={containerMotion}
          initial="hidden"
          whileInView="show"
          viewport={{ root: scrollRef }}
        >
          <Container size={'xl'} style={{ zIndex: 1, position: 'relative' }}>
            <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
              <S.StyledTitle order={2}>
                <FormattedMessage id="download.title" />
              </S.StyledTitle>
            </motion.div>
            <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
              <Text component="p" c={'dark'}>
                <FormattedMessage id="download.description" />
              </Text>
            </motion.div>
            <motion.div variants={itemMotion} viewport={{ root: scrollRef }}>
              <Group mt="md">
                <Anchor
                  className="relative h-[60px] block transition-all duration-200 hover:scale-105"
                  style={{ aspectRatio: 3 / 1 }}
                  href={appStoreLink}
                  target="_blank"
                  rel="noreferrer"
                  unstyled
                >
                  <Image
                    alt={isEn ? 'Download on the App Store' : 'Baixe na App Store'}
                    src={`/assets/badges/app-store-${isEn ? 'en' : 'pt'}.svg`}
                    fill
                    style={{ objectFit: 'contain' }}
                  />
                </Anchor>
                <Anchor
                  className="relative h-[60px] block transition-all duration-200 hover:scale-105"
                  style={{ aspectRatio: 27 / 8 }}
                  href={playStoreLink}
                  target="_blank"
                  rel="noreferrer"
                  unstyled
                >
                  <Image
                    alt={isEn ? 'Get it on Google Play' : 'Baixe na Google Play'}
                    src={`/assets/badges/google-play-${isEn ? 'en' : 'pt'}.png`}
                    fill
                    style={{ objectFit: 'contain' }}
                  />
                </Anchor>
              </Group>
            </motion.div>
          </Container>
        </motion.div>

        <Box w={600} bottom={0} right={0} h={'600px'} pos={'absolute'}>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            transition={{ delay: 0.5, duration: 1, mass: 1, type: 'tween' }}
            whileInView={{ opacity: 1, x: 0 }}
            style={{ height: '100%' }}
            viewport={{ root: scrollRef }}
          >
            <MediaQuery query="(max-width: 950px)" styles={{ width: '50vw', height: '50vw' }}>
              <Image
                width={600}
                height={600}
                style={{
                  objectFit: 'contain',
                  height: 'auto',
                  zIndex: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                }}
                src="/assets/onde-encontrar-2.jpg"
                alt={'Download'}
              />
            </MediaQuery>
          </motion.div>
        </Box>
      </Box>
    </section>
  );
};

export default DownloadSection;
